@import url(https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&family=Rubik:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400&display=swap);
body {
  margin: 0;
  font-family: "Quicksand", "Rubik", -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  transition: all linear 100ms;
}

*,
*::before,
*::after {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  /* overflow: hidden; */
}

html {
  font-family: "Quicksand", "Rubik", -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif !important;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.MuiButtonBase-root:disabled {
  cursor: not-allowed !important;
  pointer-events: auto !important;
}

.MuiPopover-paper {
  overflow-x: auto !important;
}

/* .MuiInputLabel-outlined {
  background: white !important;
} */

/* @media (prefers-color-scheme: light) {
  .MuiInputLabel-outlined {
    background: white !important;
  }
} */
/* @media (prefers-color-scheme: dark) {
  .MuiInputLabel-outlined {
    background: none !important;
  }
} */

#logo {
  font-family: "Rubik";
  font-weight: 900;
}

label {
  font-family: "Rubik";
}

.services .service {
  padding: 20px;
  border:1px solid #ccc;
  background-color:#fff;
  text-align:left;
  margin-top: 10px;
  transition: ease-in 0.2s all;
  box-shadow: 0 0 10px #ccc;
  width: 100%;
}
.services .service:hover {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  transform: translateY(-5px);
}
.services .service h4 {
  font-size: 25px;
  margin-top:0;
  font-weight: bold;
  text-align:left;
  margin-bottom: 10px;
}

.services .service p {
  font-size:16px;
  color:#444;
  line-height: 30px;
  margin-bottom: 10px;
}
.services .service .btn {
  font-size:12px;
  text-decoration:none;
  background:#1e5fdf;
  color:#fff;
  padding: 3px 12px;
  line-height: 30px;
  transition: 0.2s ease-in all;
}
.services .service .btn i {
  transition: 0.2s ease-in;
  margin-left: 20px;
}
.services .service:hover .btn i {
  margin-left: 8px;
}

@media (max-width: 992px){
  .services .service h4 {
      font-size: 20px;
  }
}


.slider-home-banner {
  height: 100%;
  width: 100%;
  background-color: #000;
  padding: 0;
}

.slider-home-banner .centered {
  /* position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); */
  display:flex; 
  align-items:center; 
  text-align:center; 
  padding: 20px; 
  vertical-align: middle;
  min-height: 450px;
  padding-right: 35px;
  padding-left: 35px;
}

.centered h2 {
  font-size: 28px;
  line-height: 38px;
  font-family: 'Rubik', 'Quicksand', sans-serif;
}


.slider-home-banner .quiz_centered {
  display:flex; 
  align-items:center; 
  text-align:center; 
  padding: 20px; 
  vertical-align: middle;
  min-height: 250px;
  padding-right: 35px;
  padding-left: 35px;
}

.quiz_centered h2 {
  font-size: 20px;
  line-height: 38px;
  font-family: 'Rubik', 'Quicksand', sans-serif;
}

.slider-home-banner .options_centered {
  align-items:center; 
  text-align:center; 
  display: block;
  padding: 40px 20px 30px 40px; 
  min-height: 300px;
  background-color: #222;
}

.options_centered .optionBtn {
  font-size: 18px;
  background-color: #444;
  color:#fff;
  display: block;
  width: 100%;
  padding: 10px 20px;
  text-align: center;
  text-transform: capitalize;
  border-radius: 8px;
  margin: 0;
  line-height: 28px;
  font-family: 'Rubik', 'Quicksand', sans-serif;
}



.employee-welcome-card {
  margin-bottom: 0;
  position: relative;
  background: linear-gradient(90.31deg, #0956ca -1.02%, #183c71 192.59%);
  box-shadow: 4px 8px 6px -6px #222;
  min-height: 200px;
}
.employee-welcome-card::before {
  content: "";
  position: absolute;
  top: 0;
  right: 20px;
  border-radius: 0px 0px 10px 0px;
  width: 100px;
  height: 100%;
  transform: skew(12deg);
  background: linear-gradient(90.31deg, #0956ca -1.02%, #183c71 192.59%);
}
@media (max-width: 1399px) {
  .employee-welcome-card::before {
    display: none;
  }
}
.employee-welcome-card .employee-img {
  position: absolute;
  right: 0;
}
.employee-welcome-card .card-body {
  padding: 15px;
}
.employee-welcome-card .welcome-info {
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
  margin-bottom: 24px;
  position: relative;
  z-index: 1;
}
.employee-welcome-card .welcome-info .welcome-content h5 {
  font-size: 18px;
  color: #FFF;
  margin-bottom: 5px;
}
.employee-welcome-card .welcome-info .welcome-content p {
  color: #EFF0F1;
  margin-bottom: 0;
}
.employee-welcome-card .welcome-info .welcome-content p span {
  font-weight: 500;
  color: #FFF;
  padding-bottom: 3px;
  border-bottom: 1px solid #FFF;
}
.employee-welcome-card .welcome-info .welcome-img {
  width: 85px;
  height: 85px;
  border-radius: 50%;
}
.employee-welcome-card .welcome-info .welcome-img img {
  width: 85px;
  height: 85px;
  border-radius: 50%;
}
.employee-welcome-card .welcome-btn {
  position: relative;
  z-index: 1;
}
.employee-welcome-card .welcome-btn .btn {
  font-size: 15px;
  font-weight: 500;
  color: #373B3E;
  background: #FFEBDA;
  border: 1px solid #FFAF69;
  border-radius: 5px;
  line-height: normal;
  padding: 9px 12px;
  min-width: 110px;
}
.employee-welcome-card .welcome-btn .btn:hover {
  color: #373B3E;
  background: #FFD7B4;
  border-color: #FFD7B4;
}


/* profilebox */
.profilebox {border-bottom: 1px solid #e2e6e9; background: #fff; padding: 0; margin: 5px 0 10px 0; display: block; box-shadow: 4px 8px 6px -6px #222;
  padding: 15px 20px; color: #456; text-decoration: none; border-radius: 9px;}
.profilebox h4 {font-size: 16px;}

.logobox {height: 100px; width: 100px; text-align: center; margin: auto;}
.logobox img {height: 100px; width: 100px;}

.namebox {border-bottom: 1px solid #ccc; margin-top: 20px; padding: 0 10px; word-wrap: break-word;}
.namebox-no-mt {border-bottom: 1px solid #ccc; margin-top: 0; padding: 0 10px; word-wrap: break-word;}
.namebox h4, .namebox-no-mt h4 {font-size: 15px; font-weight: bold; text-align: center; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;}


.detailbox {margin-top: 15px;}
.detailbox p {font-size: 13px; padding: 5px 0; margin: 0; text-align: left; font-weight: 600; line-height: normal;}
.detailbox p span.title {width: 100%; display: inline-block;}

.detailbox p span.det {opacity: .5;  display: inline-block; float:left;}




.statistic-header {
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
  padding-bottom: 15px;
}
.statistic-header h4 {
  font-size: 18px;
  font-weight: 700;
  color: #212529;
  margin-bottom: 0;
}


.statistic-header .important-notification a {
  color: #373B3E;
  font-weight: 700;
  font-size: 13px;
  display: inline-flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  line-height: normal;
}
.statistic-header .important-notification a:hover {
  color: #FF902F;
}
.statistic-header .important-notification a i {
  margin-left: 10px;
}



.task_statistics {
  max-height: 350px;
  height: 350px;
  overflow: auto;
  /* box-shadow: 4px 8px 6px -6px #222; */
  /* border-radius: 9px; */
}

@media (max-width: 992px){
  .task_statistics {
    max-height: 250px;
    height: 250px;
    overflow: auto;
  }
}


.employee-noti-content {
  padding: 0;
  width: 100%;
  display: block;
}

.notification_pad {
  padding: 10px 10px 10px 0px !important;
}

.notification_page_pad {
  padding: 20px 20px 10px 20px !important;
}

.employee-noti-content .employee-notification-list {
  list-style: none;
  /* background-color: red; */
}

.employee-noti-content .employee-notification-grid {
  display: flex;
  list-style: none;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  box-shadow: 0px 4px 24px 0px rgba(188, 188, 188, 0.2509803922);
  padding: 15px;
  margin-left: 10px;
  /* background-color: blue; */
  margin-bottom: 15px;
  width: 100%;
}
.employee-noti-content .employee-notification-grid:last-child {
  margin-bottom: 0;
}
.employee-noti-content .employee-notification-grid .employee-notification-icon {
  margin-right: 8px;
  width: 45px;
  min-width: 45px;
  height: 45px;
}

.employee-noti-content .employee-notification-grid .employee-notification-button {
  margin-right: 8px;
  width: 45px;
  min-width: 45px;
  height: 45px;
  float: right;
}
.employee-noti-content .employee-notification-grid .employee-notification-icon span {
  font-weight: 500;
  width: 45px;
  min-width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
}
.employee-noti-content .employee-notification-grid .employee-notification-content h6 {
  font-size: 16px;
  margin-bottom: 5px;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  cursor: pointer;
}
.employee-noti-content .employee-notification-grid .employee-notification-content h6 a {
  color: #373B3E;
}
.employee-noti-content .employee-notification-grid .employee-notification-content h6 a:hover {
  color: #FF902F;
}
.employee-noti-content .employee-notification-grid .employee-notification-content .nav li {
  font-size: 12px;
  color: #7A7C7F;
  padding-right: 5px;
  position: relative;
  list-style: none;
}
.employee-noti-content .employee-notification-grid .employee-notification-content .nav li:last-child {
  padding-left: 5px;
  padding-right: 0;
}
.employee-noti-content .employee-notification-grid .employee-notification-content .nav li + li:after {
  content: "";
  /* position: absolute; */
  top: 1px;
  left: 0;
  width: 1px;
  height: 15px;
  background: #D3D3D4;
}

.badge-soft-danger {
  color: #f06548;
  background-color: rgba(240, 101, 72, 0.1);
}

.rounded-circle {
  border-radius: 50% !important;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

